<template>
  <div class="addApplication">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      labelWidth="200px"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      :footerShow="isShowFooter"
      @update="update"
    >
      <div class="title">社区基本信息</div>
      <el-form-item
        label="社区名称"
        :rules="[
          { required: false, message: '请选择社区名称', trigger: 'blur' },
        ]"
        prop="tenantId"
      >
        <v-select
          :disabled="true"
          placeholder="请选择社区名称"
          :options="tenantList"
          v-model="form.tenantId"
          :width="formWidth"
        ></v-select>
      </el-form-item>
      <el-form-item
        label="未来社区编码"
        :rules="[
          {
            required: false,
            message: '请选择未来社区编码',
            trigger: 'blur',
          },
        ]"
        prop="houseCode"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          v-model="form.houseCode"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="AppKey"
        :rules="[
          {
            required: false,
            message: '请输入appKey',
            trigger: ['blur'],
          },
        ]"
        prop="szdzAppKey"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          placeholder="请输入AppKey"
          v-model="form.szdzAppKey"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="Kafka Topic"
        :rules="[
          {
            required: false,
            message: '请输入kafkaTopic',
            trigger: 'blur',
          },
        ]"
        prop="kafkaTopic"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          placeholder="请输入Kafka Topic"
          v-model="form.kafkaTopic"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="Kafka GroupId"
        :rules="[
          { required: false, message: '请选择KafkaGroupId', trigger: 'blur' },
        ]"
        prop="kafkaGroupId"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          v-model="form.kafkaGroupId"
        />
      </el-form-item>
      <el-form-item
        label="Kafka 账号"
        :rules="[
          { required: false, message: '请输入kafka账号', trigger: 'blur' },
        ]"
        prop="kafkaAccount"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          v-model="form.kafkaAccount"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="Kafka 密码"
        :rules="[
          { required: false, message: '请输入kafka账号', trigger: 'blur' },
        ]"
        prop="kafkaPwd"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          v-model="form.kafkaPwd"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="Kafka/接口数据解密私钥"
        :rules="[
          {
            required: false,
            message: '请输入接口数据解密私钥',
            trigger: 'blur',
          },
        ]"
        prop="kafkaDecrKey"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          v-model="form.kafkaDecrKey"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="运营数据appId"
        :rules="[
          {
            required: false,
            message: '请输入运营数据appId',
            trigger: 'blur',
          },
        ]"
        prop="opsAppId"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          v-model="form.opsAppId"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="运营数据appSecret"
        :rules="[
          {
            required: false,
            message: '请输入运营数据appSecret',
            trigger: 'blur',
          },
        ]"
        prop="opsAppSecret"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          v-model="form.opsAppSecret"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="接口解密私钥"
        :rules="[
          {
            required: false,
            message: '请输入接口解密私钥',
            trigger: 'blur',
          },
        ]"
        prop="szdzSm2PrivateKey"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          v-model="form.szdzSm2PrivateKey"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="接口请求公钥"
        :rules="[
          {
            required: false,
            message: '请输入接口请求公钥',
            trigger: 'blur',
          },
        ]"
        prop="szdzSm2PublicKey"
      >
        <v-input
          :disabled="!isEdit"
          :width="formWidth"
          v-model="form.szdzSm2PublicKey"
        ></v-input>
      </el-form-item>
      <div class="title">小区清单</div>
      <div
        class="form-item"
        v-for="(item, index) in form.communityList"
        :key="index"
      >
        <div class="form-item-l">
          <el-form-item
            label="小区名称"
            :rules="[
              {
                required: false,
                message: '请选择小区',
                trigger: 'blur',
              },
            ]"
            :prop="`communityList.${index}.spaceId`"
          >
            <v-select
              :disabled="true"
              placeholder="请选择小区"
              :options="complexCode"
              @change="handleSelectComplexCode($event, item)"
              v-model="item.spaceId"
              :width="formWidth"
            >
            </v-select>
          </el-form-item>
          <el-form-item
            label="小区编码"
            :rules="[
              {
                required: false,
                message: '请输入小区编码',
                trigger: 'blur',
              },
            ]"
            :prop="`communityList.${index}.houseCode`"
          >
            <v-input
              :disabled="!isEdit"
              :width="formWidth"
              v-model="item.houseCode"
            ></v-input>
          </el-form-item>
          <el-form-item
            label="浙往通二维码解密私钥"
            :rules="[
              {
                required: false,
                message: '请输入浙往通二维码解密私钥',
                trigger: 'blur',
              },
            ]"
            :prop="`communityList.${index}.qrDecrKey`"
          >
            <v-input
              :disabled="!isEdit"
              :width="formWidth"
              v-model="item.qrDecrKey"
            ></v-input>
          </el-form-item>
        </div>
        <div class="form-item-r">
          <!-- <v-button
            text="删除"
            :disabled="!isEdit"
            type="danger"
            v-if="form.communityList.length > 1 && isEdit"
            @click="deleteCommunityInfo(index)"
          ></v-button>
          <v-button
            v-if="form.communityList.length - 1 == index && isEdit"
            text="添加"
            @click="addCommunityInfo"
          ></v-button> -->
        </div>
      </div>
    </form-panel>
  </div>
</template>

<script>
import {
  getTenantInfoList,
  getTenantInfoDetails,
  updateImplementation,
  getTenantList,
  getComplexList,
} from "./api";
import { _localStorage } from "@/utils/utils.js";
export default {
  name: "addApplication",
  data() {
    return {
      formWidth: 250,
      submitConfig: {
        queryUrl: getTenantInfoDetails, //查询接口
        submitUrl: updateImplementation, //提交接口
      },
      form: {
        id: "", //编辑id
        tenantId: "", //租户id
        tenantName: "", //社区名称
        houseCode: "", //未来社区编码
        szdzAppKey: "", //appKek
        kafkaTopic: "", //kafkaTopic
        kafkaGroupId: "", //kafkaGroupId
        kafkaAccount: "", //kafka账号
        kafkaPwd: "", //kafka密码
        kafkaDecrKey: "", //kafka接口数据解密私钥
        opsAppId: "", //运营数据appId
        opsAppSecret: "", //运营数据appSecret
        szdzSm2PrivateKey: "", //接口解密私钥
        szdzSm2PublicKey: "", // 接口请求公钥
        communityList: [
          {
            id: "",
            name: "", //小区名称
            spaceId: "", //小区编码
            houseCode: "", //小区未来社区编码
            qrDecrKey: "", //浙网通二维码解密私钥
          },
        ],
      },
      tenantList: [], //社区列表
      complexCode: [], //小区编码
      tenantId: "",
      isEdit: true,
      isShowFooter: true, //底部按钮
    };
  },
  computed: {},
  watch: {},
  created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.tenantId = userInfo.tenantId;
    }
    this.getTenantList();
    this.getComplexList();
  },
  mounted() {
    const { id, isEdit } = this.$route.query;
    if (id !== undefined) {
      this.isEdit = isEdit;
      this.isEdit ? (this.isShowFooter = true) : (this.isShowFooter = false);
      this.$refs.formPanel.getData({ id: id });
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    //选择小区
    handleSelectComplexCode(arg, item) {
      const curIndex = this.complexCode.findIndex((v) => v.spaceId == arg);
      const name = this.complexCode[curIndex]?.name;
      this.$set(item, "name", name);
    },
    //获取小区编码列表
    async getComplexList() {
      const params = {
        tenantId: this.tenantId,
      };
      const res = await this.$axios.post(
        getComplexList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        const { data } = res;
        data.forEach((item) => {
          item.value = item.spaceId;
          item.label = item.name;
        });
        this.complexCode = data;
      }
    },
    //获取社区列表
    async getTenantList() {
      const params = {
        tenantId: this.tenantId,
      };
      const res = await this.$axios.post(
        getTenantList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        this.tenantList = [];
        const { data } = res;
        data.forEach((ele) => {
          let obj = {};
          obj.label = ele.tenantName;
          obj.value = ele.tenantId;
          this.tenantList.push(obj);
        });
      }
    },
    //添加小区清单
    addCommunityInfo() {
      const params = {
        id: "",
        name: "", //小区名称
        spaceId: "", //小区编码
        houseCode: "", //小区未来社区编码
        qrDecrKey: "", //浙网通二维码解密私钥
      };
      this.form.communityList.push(params);
    },
    deleteCommunityInfo(index) {
      this.form.communityList.splice(index, 1);
    },
    submitSuccess(data) {
      return true;
    },
    submitBefore() {
      return true;
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.addApplication {
  box-sizing: border-box;
  height: 100%;
  // /deep/ .el-form-item__label {
  // }
  .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .form-item {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    margin-bottom: 22px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    .form-item-l {
      box-sizing: border-box;
      /deep/.el-form-item {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .form-item-r {
      display: flex;
      gap: 10px;
    }
  }
}
</style>
