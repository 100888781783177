var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addApplication"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"labelWidth":"200px","submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess,"footerShow":_vm.isShowFooter},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('div',{staticClass:"title"},[_vm._v("社区基本信息")]),_c('el-form-item',{attrs:{"label":"社区名称","rules":[
        { required: false, message: '请选择社区名称', trigger: 'blur' },
      ],"prop":"tenantId"}},[_c('v-select',{attrs:{"disabled":true,"placeholder":"请选择社区名称","options":_vm.tenantList,"width":_vm.formWidth},model:{value:(_vm.form.tenantId),callback:function ($$v) {_vm.$set(_vm.form, "tenantId", $$v)},expression:"form.tenantId"}})],1),_c('el-form-item',{attrs:{"label":"未来社区编码","rules":[
        {
          required: false,
          message: '请选择未来社区编码',
          trigger: 'blur',
        },
      ],"prop":"houseCode"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(_vm.form.houseCode),callback:function ($$v) {_vm.$set(_vm.form, "houseCode", $$v)},expression:"form.houseCode"}})],1),_c('el-form-item',{attrs:{"label":"AppKey","rules":[
        {
          required: false,
          message: '请输入appKey',
          trigger: ['blur'],
        },
      ],"prop":"szdzAppKey"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth,"placeholder":"请输入AppKey"},model:{value:(_vm.form.szdzAppKey),callback:function ($$v) {_vm.$set(_vm.form, "szdzAppKey", $$v)},expression:"form.szdzAppKey"}})],1),_c('el-form-item',{attrs:{"label":"Kafka Topic","rules":[
        {
          required: false,
          message: '请输入kafkaTopic',
          trigger: 'blur',
        },
      ],"prop":"kafkaTopic"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth,"placeholder":"请输入Kafka Topic"},model:{value:(_vm.form.kafkaTopic),callback:function ($$v) {_vm.$set(_vm.form, "kafkaTopic", $$v)},expression:"form.kafkaTopic"}})],1),_c('el-form-item',{attrs:{"label":"Kafka GroupId","rules":[
        { required: false, message: '请选择KafkaGroupId', trigger: 'blur' },
      ],"prop":"kafkaGroupId"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(_vm.form.kafkaGroupId),callback:function ($$v) {_vm.$set(_vm.form, "kafkaGroupId", $$v)},expression:"form.kafkaGroupId"}})],1),_c('el-form-item',{attrs:{"label":"Kafka 账号","rules":[
        { required: false, message: '请输入kafka账号', trigger: 'blur' },
      ],"prop":"kafkaAccount"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(_vm.form.kafkaAccount),callback:function ($$v) {_vm.$set(_vm.form, "kafkaAccount", $$v)},expression:"form.kafkaAccount"}})],1),_c('el-form-item',{attrs:{"label":"Kafka 密码","rules":[
        { required: false, message: '请输入kafka账号', trigger: 'blur' },
      ],"prop":"kafkaPwd"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(_vm.form.kafkaPwd),callback:function ($$v) {_vm.$set(_vm.form, "kafkaPwd", $$v)},expression:"form.kafkaPwd"}})],1),_c('el-form-item',{attrs:{"label":"Kafka/接口数据解密私钥","rules":[
        {
          required: false,
          message: '请输入接口数据解密私钥',
          trigger: 'blur',
        },
      ],"prop":"kafkaDecrKey"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(_vm.form.kafkaDecrKey),callback:function ($$v) {_vm.$set(_vm.form, "kafkaDecrKey", $$v)},expression:"form.kafkaDecrKey"}})],1),_c('el-form-item',{attrs:{"label":"运营数据appId","rules":[
        {
          required: false,
          message: '请输入运营数据appId',
          trigger: 'blur',
        },
      ],"prop":"opsAppId"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(_vm.form.opsAppId),callback:function ($$v) {_vm.$set(_vm.form, "opsAppId", $$v)},expression:"form.opsAppId"}})],1),_c('el-form-item',{attrs:{"label":"运营数据appSecret","rules":[
        {
          required: false,
          message: '请输入运营数据appSecret',
          trigger: 'blur',
        },
      ],"prop":"opsAppSecret"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(_vm.form.opsAppSecret),callback:function ($$v) {_vm.$set(_vm.form, "opsAppSecret", $$v)},expression:"form.opsAppSecret"}})],1),_c('el-form-item',{attrs:{"label":"接口解密私钥","rules":[
        {
          required: false,
          message: '请输入接口解密私钥',
          trigger: 'blur',
        },
      ],"prop":"szdzSm2PrivateKey"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(_vm.form.szdzSm2PrivateKey),callback:function ($$v) {_vm.$set(_vm.form, "szdzSm2PrivateKey", $$v)},expression:"form.szdzSm2PrivateKey"}})],1),_c('el-form-item',{attrs:{"label":"接口请求公钥","rules":[
        {
          required: false,
          message: '请输入接口请求公钥',
          trigger: 'blur',
        },
      ],"prop":"szdzSm2PublicKey"}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(_vm.form.szdzSm2PublicKey),callback:function ($$v) {_vm.$set(_vm.form, "szdzSm2PublicKey", $$v)},expression:"form.szdzSm2PublicKey"}})],1),_c('div',{staticClass:"title"},[_vm._v("小区清单")]),_vm._l((_vm.form.communityList),function(item,index){return _c('div',{key:index,staticClass:"form-item"},[_c('div',{staticClass:"form-item-l"},[_c('el-form-item',{attrs:{"label":"小区名称","rules":[
            {
              required: false,
              message: '请选择小区',
              trigger: 'blur',
            },
          ],"prop":`communityList.${index}.spaceId`}},[_c('v-select',{attrs:{"disabled":true,"placeholder":"请选择小区","options":_vm.complexCode,"width":_vm.formWidth},on:{"change":function($event){return _vm.handleSelectComplexCode($event, item)}},model:{value:(item.spaceId),callback:function ($$v) {_vm.$set(item, "spaceId", $$v)},expression:"item.spaceId"}})],1),_c('el-form-item',{attrs:{"label":"小区编码","rules":[
            {
              required: false,
              message: '请输入小区编码',
              trigger: 'blur',
            },
          ],"prop":`communityList.${index}.houseCode`}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(item.houseCode),callback:function ($$v) {_vm.$set(item, "houseCode", $$v)},expression:"item.houseCode"}})],1),_c('el-form-item',{attrs:{"label":"浙往通二维码解密私钥","rules":[
            {
              required: false,
              message: '请输入浙往通二维码解密私钥',
              trigger: 'blur',
            },
          ],"prop":`communityList.${index}.qrDecrKey`}},[_c('v-input',{attrs:{"disabled":!_vm.isEdit,"width":_vm.formWidth},model:{value:(item.qrDecrKey),callback:function ($$v) {_vm.$set(item, "qrDecrKey", $$v)},expression:"item.qrDecrKey"}})],1)],1),_c('div',{staticClass:"form-item-r"})])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }