
// 获取社区列表
const getTenantInfoList = `/gateway/hc-portal/szdz/zzt/getTenantInfoList`;
// 获取社区配置单详情
const getTenantInfoDetails = `/gateway/hc-portal/szdz/zzt/getTenantInfoDetails`;
// 内容实施单编辑
const updateImplementation= `/gateway/hc-portal/szdz/zzt/update`;
//租户及社区编码列表
const getTenantList = `/gateway/hc-portal/szdz/tenantList`;
//小区编码列表
const getComplexList = `/gateway/hc-portal/szdz/complexList`;
export {
  getTenantInfoList,
  getTenantInfoDetails,
  updateImplementation,
  getTenantList,
  getComplexList
};
